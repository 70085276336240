@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ddd;
  background: #140125;
  overscroll-behavior-y: none;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  position: relative;
  pointer-events: all;
  color: #ddd;
  text-decoration: none;
  padding: 20px;
}

svg {
  fill: #ddd;
}

h1 {
  background: linear-gradient(30deg, #730d6c, #e69647);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 3em;
}

h2 {
  background: linear-gradient(90deg, #730d6c, #f59739);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  margin: 0px;
  padding: 0;
  font-size: 7em;
  font-weight: 500;
  letter-spacing: -0.02em;
  position: absolute;
  top: 0px;
  right: -50px;
  left: -50px;
}

h3 {
  background: linear-gradient(30deg, #e813b6, #f99913);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 3em;
}

h4 {
  background: linear-gradient(30deg, #730d6c, #e69647);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

  font-size: 2em;
}

.mobile-nav {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.header {
  display: flex;
  align-items: top;
  padding: 10px;
  padding-left: 40px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 3;
}

.nav {
  padding-left: 40px;
  padding-top: 50px;
}

.info {
  background: linear-gradient(180deg, #ffffff, #f5e2ff);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 0px;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-bottom: 50px;
}

.award {
  margin-top: 10px;

  z-index: 4;
}

.clients {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 100%;
  border-radius: 0px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 180px;
  background: #fffffa;
}

.testimonials {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  background: #140125;

  justify-content: center;
  align-items: center;
  padding: 50px;
}

/* button */
.button {
  align-items: right;
  appearance: none;
  background-color: #3eb2fd;
  background-image: linear-gradient(90deg, #f2376f, #f99913 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd, sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  margin-top: 2px;
  text-align: right;
  text-decoration: none;
  transition: background-color 0.2s, background-position 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button:active,
.button:focus {
  outline: none;
}

.button:hover {
  background-position: -20px -20px;
}

.button:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 0.125em;
}

.slider-container {
  position: relative;
  width: 400px;
  height: 400px;
}

.slide {
  border-radius: 25px;
  background-image: url(./studies/4.svg);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.slideSibling {
  border-radius: 25px;
  background-image: url(./studies/4.svg);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  scale: 0.5;
  z-index: 10;
}

.slide[data-page='0'] {
  border-radius: 25%;
  background-image: url(./studies/1.svg);
}

.slide[data-page='1'] {
  border-radius: 25%;
  background-image: url(./studies/2.svg);
}

.slide[data-page='2'] {
  border-radius: 25%;
  background-image: url(./studies/3.svg);
}

.slideSibling[data-page='0'] {
  border-radius: 25%;
  background-image: url(./studies/1.svg);
}

.slideSibling[data-page='1'] {
  border-radius: 25%;
  background-image: url(./studies/2.svg);
}

.slideSibling[data-page='2'] {
  border-radius: 25%;
  background-image: url(./studies/3.svg);
}

@media screen and (max-width: 768px) {
  a {
    position: relative;
    pointer-events: all;
    color: #ddd;
    text-decoration: none;
    padding: 20px;
  }

  h1 {
    background: linear-gradient(30deg, #730d6c, #e69647);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 3em;
  }

  h2 {
    background: linear-gradient(90deg, #730d6c, #f59739);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    margin: 0px;
    padding: 0;
    font-size: 7em;
    font-weight: 500;
    letter-spacing: -0.02em;
    position: absolute;
    top: 0px;
    right: -50px;
    left: -50px;
  }

  h3 {
    background: linear-gradient(30deg, #e813b6, #f99913);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 3em;
  }

  h4 {
    background: linear-gradient(30deg, #730d6c, #e69647);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;

    font-size: 1.5em;
  }

  .mobile-nav {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

  .header {
    display: flex;
    align-items: top;
    padding: 10px;
    padding-left: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 3;
  }

  .nav {
    padding-left: 10px;
    padding-top: 50px;
  }

  .info {
    background: linear-gradient(180deg, #ffffff, #f5e2ff);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    padding: 40px;
    padding-bottom: 50px;
  }

  .award {
    margin-top: 10px;

    z-index: 4;
  }

  .clients {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 100%;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 180px;
    background: #fffffa;
  }

  .testimonials {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    justify-content: center;
    align-items: center;
    padding: 50px;
  }
}
